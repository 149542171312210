@import '../../../../../index.scss';

.searchinput-wrapper input{
    height: 40px;
    font-size: 10pt;
    outline: none;
    border: 1px solid rgb(175, 175, 175);
    padding: 0 20px;
    border-radius: 10px;
    color: $textColor;
}

.searchinput-filter{
    width: 90% !important;
    margin: 0 auto;
}

.MuiInputBase-root {
    background-color: rgba(255, 255, 255, 0.753) !important;
}

.auto-sizing{
    width: 70% !important;
}
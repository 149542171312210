.container-portal {
  display: flex !important;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 100vh;
}

.button-create-portal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 5rem;
  font-size: 1.2rem;
  margin: 1rem;
}
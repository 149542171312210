@import "../../../index.scss";

.container-filters {
  justify-content: space-between;
  align-items: center;
}

// .radio-filter {
// }

.select-manufacturer {
  margin-top: 15px;
  margin-bottom: 5px;
  margin-right: 20px;
}

.datatable-outer {
  overflow-x: auto;
}

.datatable-outer th,
.datatable-outer td {
  min-width: 100px;
}

.datable-main {
  table-layout: fixed;
}

.datable-head-row {
  background-color: transparent;
}

.datatable-header-col {
  color: $primaryColor  !important;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
  font-size: 11pt;
  border-bottom: 1px solid $textColorRest;
}

.datatable-header-col>div>p {
  margin: auto;
  text-align: center;
  align-content: center;
  width: 100%;
  font-size: 12pt;
}

.arrow-up {
  background-image: url("../../../assets/right-arrow.svg");
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  filter: brightness(0.6);
  opacity: 0;
}

.arrow-up.show,
.arrow-down.show {
  opacity: 1;
}

.datatable-header-col:hover,
.datatable-header-col:hover .arrow-up,
.datatable-header-col:hover .arrow-down {
  cursor: pointer;
  opacity: 1;
}

.datatable-row {
  height: 50px;
  padding: 0 20px !important;
}

.datatable-row-col {
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $textColor;
  text-align: center;
  align-content: center;
  font-size: 10pt;
  font-weight: 400;
  border-bottom: 1px solid rgba(209, 209, 209, 0.582);
}

.datatable-row-odd th,
.datatable-row-even th {
  // padding: 0;
  margin: 0;
}

.datatable-row-odd:hover th,
.datatable-row-even:hover th {
  color: white;
  font-weight: bold;
}

.datatable-row-even:hover,
.datatable-row-odd:hover {
  background-color: $tableHover;
  cursor: pointer;
  margin: 0;
  // padding: 0;
}

.datatable-row-even {
  background-color: white;
}

.datatable-row-odd {
  background-color: #f8f9fa;
  margin: 0;
  // padding: 0;
}

.unviewed {
  font-weight: bold;
}
@import '../../../index.scss';

.datatable-legend {
  color: rgba(0, 0, 0, 0.8);
  text-align: left;
}

.datable-main {
  table-layout: fixed;
}

.datatable-header-col>div>p {
  margin: auto;
  text-align: center;
  align-content: center;
  width: 100%;
  font-size: 12pt;
}

.datable-head-row {
  background-color: transparent;
}

.dl-button:hover {
  cursor: pointer;
  color: #333;
}

.datatable-header-col {
  color: $primaryColor  !important;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
  font-size: 11pt;
  border-bottom: 1px solid $textColorRest;
}

.datatable-row {
  height: 50px;
  padding: 0 20px !important;
}

.datatable-row-col {
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $textColor;
  text-align: center;
  align-content: center;
  font-size: 10pt;
  font-weight: 0;
  border-bottom: 1px solid rgba(209, 209, 209, 0.582);
}

.datatable-row-odd {
  background-color: #f8f9fa;
  margin: 0;
  // padding: 0;
}

.datatable-row-odd th,
.datatable-row-even th {
  // padding: 0;
  margin: 0;
}

/* .datatable-row-odd:hover {
  background-color: #6fb5fa;
  cursor: pointer;
} */

.datatable-row-odd:hover th,
.datatable-row-even:hover th {
  color: #555555 !important;
  
}


.datatable-row-even:hover,
.datatable-row-odd:hover {
  background-color: #fffce9 !important;
  cursor: pointer;
  margin: 0;
  // padding: 0;
}

.text-primary {
  color: $primaryColor  !important;
}

.datatable-row-even {
  background-color: white;
}

.datatable-outer {
  overflow-x: auto;
}

.datatable-outer th,
.datatable-outer td {
  min-width: 100px;
}

.arrow-up {
  background-image: url("../../../assets/right-arrow.svg");
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  filter: brightness(.6);
  opacity: 0;
}

.arrow-down {
  background-image: url("../../../assets/right-arrow.svg");
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
  filter: brightness(.6);
  opacity: 0;
}

.arrow-up.show,
.arrow-down.show {
  opacity: 1;
}

.datatable-header-col:hover,
.datatable-header-col:hover .arrow-up,
.datatable-header-col:hover .arrow-down {
  cursor: pointer;
  opacity: 1;
}

.unviewed {
  font-weight: bold;
}

.legend-multiple {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  background-color: #1367ec;
  border-radius: 1px;
}

.legend-matriz {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  background-color: #092a60;
  border-radius: 1px;
}
.display-none {
  display: none;
}

.display-block {
  display: block;
}

.select-option-dashboard {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: 45%;
}
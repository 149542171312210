.container-button-type {
  padding-bottom: 0.5rem;

  :hover {
    background-color: rgba(49, 105, 179, 0.2);
  }
}

.button-feedback-type {
  background: none;
  padding: 0;
  outline: inherit;
  flex-direction: row !important;
  padding: 0.4rem;
  flex: 1;
  width: 7rem;
  height: 5rem;
  border-color: #7b78d3;
  border-radius: 0.5rem;

  :hover {
    background-color: rgba(49, 105, 179, 0.01);
  }
}
@import '../../../index.scss';

.user-info-main-wrapper .btn-primary,
.user-info-main-wrapper .btn-primary:focus,
.user-info-main-wrapper .btn-primary:hover,
.user-info-main-wrapper .btn-primary.dropdown-toggle,
.user-info-main-wrapper .btn-primary.dropdown-toggle:focus {
  background-color: white;
  border: none;
  box-shadow: none;
}

.user-info-dropdown-item {

  &:hover {
    color: #524ec6;
  }

  &:active {
    background-color: #7b78d3;
  }
}

.user-info-nav-link {
  text-decoration: none;
  color: $textColor;

  &:hover {
    color: #524ec6;
  }

}
.title-modal-portal {
  font-size: 1.1rem;
}

.container-name-portal {
  padding: 0.9rem;
  margin-top: -1rem;
}

.label-name {
  font-size: 0.9rem;
  font-weight: bold;
  font-family: Roboto;
}

.input-name {
  border: 1px solid;
  border-radius: 0.2rem;
  width: 10rem;
  margin-left: 1.4rem;
  font-family: Roboto;
  padding: 0.3rem;
  font-size: 0.8rem;
}

.container-site-portal {
  padding: 0.9rem;
}

.label-portal {
  font-size: 0.9rem;
  font-weight: bold;
  font-family: Roboto;
}

.input-site {
  border: 1px solid;
  border-radius: 0.2rem;
  width: 10rem;
  margin-left: 2.15rem;
  font-family: Roboto;
  padding: 0.3rem;
  font-size: 0.8rem;
}

.container-select-company {
  padding: 0.9rem;
}

.label-select-company {
  font-size: 0.9rem;
  font-weight: bold;
  font-family: Roboto;
}

.input-company {
  border: 1px solid;
  border-radius: 0.2rem;
  width: 10rem;
  margin-left: 0.2rem;
  font-family: Roboto;
  padding: 0.4rem;
  font-size: 0.8rem;
}

.container-submit-form-create-portal {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.submit-form-create-portal {
  border: none;
  background-color: #5c58c9;
  color: white;
  border-radius: 5px;
  height: 2rem;
  width: 6rem;

  &:hover {
    background-color: #524ec6;
  }
}

.submit-form-create-portal-disabled {
  border: none;
  background-color: #7b78d3;
  color: white;
  border-radius: 5px;
  height: 2rem;
  width: 6rem;
}
@import '../../../index.scss';

.forgotpassword-input-control {
  width: 100% !important;
  /* margin: 0 ; */
}

.arrow {
  border: solid $primaryColor;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px 4px;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow-click-container:hover {
  cursor: pointer;
}

.forgotpassword-input-label {
  color: $primaryColor;
  font-weight: bold;
}

.email-legend{
  font-size: 110% !important;
  margin-bottom: 0;
}

.forgotpassword-title {
  font-size: 110%;
  color: $primaryColor;
  padding: 0 20px;
}

.button-subimit{
  margin-top: 0;
  width: 50%; 
  align-self: center;
}
@import '../../../index.scss';

.form-card-backdrop {
  width: 100vw;
  height: 100vh;
  background: #000;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden !important;
}

.form-card-wrapper {
  width: 45rem;
  height: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $cardMainColor;
  z-index: 1000;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  border-radius: 0.3rem;
  margin-left: 0;
  margin-right: 0;

  img {
    width: 15rem;
  }
}

.container-left-login {
  flex-direction: column;
  width: 40%;
  height: auto;
  display: flex;
  background-color: #1f1f1f;
  align-items: center;
  justify-content: center;
}

.container-right-login {
  flex-direction: column;
  width: 60%;
}

.form-card-cover {
  // position: absolute;
  width: 100%;
  height: auto;
  background-color: #1f1f1f;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}

.form-card-image-small-text {
  color: #fff;
  padding: 0 20px;
  font-size: 13pt;
  font-weight: bold;

  span {
    font-weight: bolder;
    font-size: 14pt;
  }
}

.form-card-image-text {
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 0;
}
.form-card-image-text-libo {
  color: #fff;
  font-size: 0.8rem;
  margin-bottom: 0;
}
.petrolead-image {
  width: auto;
  height: auto;

  
}

.libo-image {
  width: 160px !important;
  height: 40px;
}

.logo-libo {
  width: 100%;
position: absolute;
bottom: 20px;
left: -225px;
}

@media(max-width: 1020px) {
  .form-card-wrapper {
    width: 25rem;
    height: 20rem;

    img {
      width: 10rem;
    }
  }

  .form-card-image-text {
    font-size: 1rem;
  }

  .form-card-image-small-text {
    font-size: 0.8rem;

    span {
      font-size: 0.9rem;
    }

  }

  .libo-image {
    width: 120px !important;
    height: 30px;
  }
  
  .logo-libo {
    width: 100%;
  position: absolute;
  bottom: 10px;
  left: -120px;
  }

  .petrolead-image {
    width: 140px !important;
    height: auto;
  
    
  }
}
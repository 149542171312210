@import '../../index.scss';

.sidemenu-container {
  position: fixed;
  top: 0;
  left: 0;
  // bottom: 0;
  height: 100vh;
  width: 240px;
  background-color: $primaryColorDark;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
  z-index: 100;
}


.side-menu-title-container p {
  color: white;
  padding: 10px;
}
.side-menu-title-container{
  
  padding: 0px;
}


.hide-sidemenu .itemWrapper p {
  opacity: 0;
  display: none;
}

.hide-sidemenu span {
  opacity: 0;
}

.side_menu_items_wrapper {
  align-items: center;
}

.sidemenu-container ul {
  padding: 0;
}




.sidemenu-logo-show {
  width: 00px;
}

.option-navigation {
  color: white !important;
  background-color: white !important;
}
.btn-items {
  border: 1px solid #524ec6;
  background-color: #fff;
  color: #524ec6;
}

.btn-items:hover {
  color: #fff !important;
  background-color: #555555;
}

.container-filter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(2.5rem, auto);
  align-items: center;
}

// .btn-priority {
//   border: 1px solid rgba(254, 200, 48, 1);
//   background-color: rgba(254, 200, 48, 0.7);
//   color: #524ec6;
// }

.btn-priority:hover {
  // color: #524ec6 !important;
  // background-color: rgba(254, 200, 48, 0.5) !important;
  opacity: .8;
}

.badge-primary {
  background-color: #1f1f1f;
}

.div-groups {
  white-space: normal;
  flex-direction: row;
  display: flex;
}

.card-groups {
  width: 29rem;
  white-space: normal;
  flex-direction: row;
  display: block;

  p {
    margin-bottom: 0px;
    font-size: 14px;
  }

  h5 {
    font-weight: 400;
  }
}

.angle-icon {
  :hover {
    cursor: pointer;
  }
}

.display-none {
  display: none;
}

.button-box {
  margin-bottom: 6px;
  margin-left: 6px;
  width: 9rem;
}

.data-button {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
  justify-content: flex-start !important;
  margin: 0.1rem;
}

.legend-button {
  margin-right: 2px;
  padding: 3px;
  margin-top: 1px;
}

@media only screen and (max-device-width: 1920px) and (min-device-width: 1590px) {
  .card-groups {
    min-width: 40rem;
    white-space: normal;
    flex-direction: row;
    display: block;

    p {
      margin-bottom: 0px;
      font-size: 14px;
    }

    h5 {
      font-weight: 400;
      font-size: 100%;
    }
  }

  .button-box {
    margin-bottom: 6px;
    margin-left: 6px;
    width: 12rem;
  }
}

@media only screen and (max-device-width: 1590px) and (min-device-width: 1281px) {
  .card-groups {
    min-width: 40%;
    white-space: normal;
    flex-direction: row;
    display: block;

    p {
      margin-bottom: 0px;
      font-size: 14px;
    }

    h5 {
      font-weight: 400;
      font-size: 100%;
    }
  }

  .button-box {
    margin-bottom: 6px;
    margin-left: 6px;
    width: 8.8rem;
  }
}

@media only screen and (max-device-width: 1280px) and (min-device-width: 1040px) {
  .card-groups {
    min-width: 40%;
    white-space: normal;
    flex-direction: row;
    display: block;

    p {
      margin-bottom: 0px;
      font-size: 12px;
    }

    h5 {
      font-weight: 400;
      font-size: 100%;
    }
  }

  .button-box {
    margin-bottom: 3px;
    margin-left: 3px;
    width: 8.5rem;
  }
}

@media only screen and (max-device-width: 1040px) and (min-device-width: 880px) {
  .card-groups {
    min-width: 40%;
    max-width: 40%;
    white-space: normal;
    flex-direction: row;
    display: block;

    p {
      margin-bottom: 0px;
      font-size: 10px;
    }

    h5 {
      font-weight: 400;
      font-size: 100%;
    }
  }

  .button-box {
    margin-bottom: 4px;
    margin-left: 4px;
    width: 5rem;
  }
}

@media only screen and (max-device-width: 640px) {
  .card-groups {
    font-size: 0.5px;
  }
}

.oportunidade-vencer {
  position: absolute;
  margin-left: 0px;
  
}
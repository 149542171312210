@import '../../../index.scss';

.logo {
  width: 30px;
  height: 30px;
  filter: grayscale(1);
}

.sidemenu-subitem-wrapper {
  background-color: $primaryColorDark;
}

.itemWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 15px !important;
  color: rgb(61, 61, 61);
}

.itemWrapper p {
  color: $textColorRest;
  transition: opacity .3s ease-in-out;

}

.itemWrapper:hover {
  cursor: pointer;
  background-color: $secondColor;
  text-decoration: none;
}

.itemWrapper:hover img {
  filter: brightness(2);
}

.itemWrapper:hover p {
  color: #1367ce;
  font-weight: bold;
}

.active-sidemenu-item p {
  color: #1367ce !important;
  font-weight: bold;
}

.active-sidemenu-item img {
  filter: brightness(2);
}

p {
  font-size: 14pt;
}

.show-subitems {
  animation-name: show;
  animation-duration: 1s;
  animation-direction: forwards;
}


.hide-subitems {
  animation-name: hide;
  animation-duration: 1s;
  animation-direction: forwards;
}

@keyframes hide {
  0% {
    height: 100px;
  }

  100% {
    height: 0;
  }
}


@keyframes show {
  0% {
    height: 0;
  }

  100% {
    height: 100px;
  }
}
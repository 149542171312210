@import '../../../../index.scss';

.sourcesmenu-container {
  position: absolute;
  padding: 0;
  margin: 0;
  height: 79px;
  top: 0px;
  right:170px;
  display: inline !important;
  width: 160px;
  background-color: transparent;
  box-shadow: 0px 0px 10px rgba(142, 169, 190, 0.521);
  z-index: 10000 !important;
}

.teste {
  float: right;
}
.cat-limiter {
  overflow-y: scroll;
  height: 25em;
}

.btn-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.btn-circle svg {
  margin-left: 2px;
  margin-right: 2px;
}

.btn-hover:hover {
  border: 1px solid #3a36a7;
}
@import "../../../../index.scss";

#logoHeader {
  padding: 0px 15px;
  width: 250px;
}

#header {
  background-color: #3169b3;
}

#buttonHeader {
  margin-top: 60px;
}

#titleHeader {
  margin-top: 60px;
  color: #fff;
  font-size: 1.5rem;
}

.formField__error {
  color: #d93025;
  font-size: 16px;
}

.btn-flat {
  color: #fff;
  background-color: #3169b3;
}

.teste {
  margin-bottom: 20px;
}
@import '../../../../index.scss';

.active-sidemenu-subitem p {
  color: $primaryColor !important;
  font-weight: bold;
}

.active-sidemenu-subitem img {
  filter: brightness(2);
}

.sidemenu-subitem-hide {
  height: 0;
  opacity: 0;
}

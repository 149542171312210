@import "../../../../index.scss";

.opportunity-piechart-wrapper h1 {
  font-size: 18pt;
  color: $textColor;
}

.dashboard-wrapper {
  position: relative;
  padding: 0 0 0 0px;
}

.oportunidadeAtiva {
  background: #3a36a7;
  color: #fff;
}

.card-body {
  cursor: pointer;
}

#line-and-column>div>svg>g>g>g>text {
  display: none;
}

.background-none ol {
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 3px;
  margin: 0;
}

.background-none li {
  color: #524ec6 !important;
  font-weight: bold;
  opacity: 0.8;
}

.left-icon-holder {
  line-height: 24px;
  margin-right: 10%;
}

.left-icon-holder .fa {
  position: absolute;
  line-height: 24px;
  top: 50%;
  margin-top: -12px;
  left: 10px;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.opportunity-view-wrapper {
  padding: 20px 0 0 20px;
}

.opportunity-view-item {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.opportunity-view-opt {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.btn-rounded {
  border-radius: 50%;
}

.opportunity-view-header-box {
  height: 80px;
  width: 13vw;
  background-color: $primaryColor;
  justify-content: start;
  padding-top: 10px;
}

.opportunity-view-header-box p {
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.opportunity-view-field-wrapper {
  margin-top: 30px;
  width: 400px;
  margin-bottom: 30px;
}

.opportunity-view-label {
  color: $primaryColorDark;
  font-size: 15pt;
  text-align: left;
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.opportunity-view-text {
  color: $primaryColor;
  font-size: 14pt;
  text-align: left;
  word-break: break-all !important;
  margin: 0;
  padding: 0;
}

.opportunity-view-field-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn {
  padding: 0.175rem 0.35rem;
}

.bodyChart {
  display: inline-block;
}

.listaFabricantes {
  height: 160px;
  width: 80%;
  justify-content: center;

  .listaConcorrentes {
    height: 40px;
    width: 800px;
    justify-content: center;
  }
}

#legenda {
  color: #524ec6;
  background: white;
  text-align: justify;
}

.pieChart {
  text-align: justify;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 9999;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #524ec6;
  border-color: #524ec6 transparent #524ec6 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.content-select {
  justify-content: center;
  flex-direction: row;
  display: flex;
}

.select {
  text-align: left !important;
  margin-right: 0px !important;
}

.multi-selectManufacturer {
  color: black !important;
}

.data-dismeter {
  color: #524ec6;
  background: white;
  margin-left: 10%;
}

.container-select-type {
  height: 50px;
}

.col-select {
  margin-right: 0px !important;
}

.button-select {
  margin-left: -15px !important;
}

.type-date {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 80px !important;
}

.type-month {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100px !important;

  button {
    width: 100% !important;
  }
}

.type-quarter {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 120px !important;
}

.type-semester {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 120px !important;
}

.type-year {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 120px !important;
}
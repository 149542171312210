.container-header-buttons {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.return-button-form {
  margin-top: 0px;
  background: none;
  padding: 0.5rem;
  outline: inherit;
  border: none;
}

.legend-form {
  padding-top: 8px;
}

.textarea-feedback {
  height: 150px;
  width: 200px;
  border-radius: 6px;
  border-color: #5c58c9;
  resize: none;
  outline: 0;
  padding: 8px;
}

.container-buttons-feedback {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.screenshot-feedback-button {
  border: none;
  background-color: rgb(21, 44, 75);
  color: white;
  border-radius: 5px;
  height: 35px;
  width: 40px;
  margin-right: 10px;

  &:hover {
    background-color: rgba(21, 44, 75, 0.8);
  }
}

.submit-feedback-button {
  border: none;
  background-color: #524ec6;
  color: white;
  border-radius: 5px;
  height: 35px;
  width: 140px;

  &:hover {
    background-color: #5c58c9;
  }
}

.submit-feedback-button-disabled {
  border: none;
  background-color: #7b78d3;
  color: white;
  border-radius: 5px;
  height: 35px;
  width: 140px;
}
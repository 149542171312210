.actions-icons {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cat-limiter {
  overflow-y: scroll;
  height: 25em;
}
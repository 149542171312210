@import "../../../index.scss";

.index-card-backdrop {
  width: 100vw;
  height: 100vh;
  background: #000;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden !important;
}

.index-card-button {
  width: 16rem;
  margin: 1rem;
 padding-bottom:0;
 margin-bottom: 112px;
  border-radius: 0.1rem;
  background-color: #1367ec;
  padding: 0.5rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  opacity: 0.9;

  &:hover {
    background-color: #092a60;
    color: white;
    text-decoration: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  &:active {
    background-color: $submitButtonActiveColor;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  }
}

.index-center-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1f1f1f;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  width: 40rem;
  height: 25rem;
  animation-name: slide-up;
  animation-duration: 1.2s;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0.3rem;

  img {
    width: 18rem !important;
    margin-top: 8rem;
  }

  p {
    opacity: 0.9;
    color: #fff;
    font-size: 1.4rem;
  }

  span {
    opacity: 0.9;
    font-size: 1.5rem;
    font-weight: bold;
  }
}

@keyframes slide-up {
  0% {
    transform: translate(-50%, 10vh);
  }

  100% {
    transform: translate(-50%, -50%);
  }
}

@media(max-width: 1020px) {
  .index-center-wrapper {
    width: 25rem;
    height: 20rem;
    padding-top: 0rem;
    padding-bottom: 0rem;

    img {
      width: 18rem !important;
    }

    p {
      opacity: 0.9;
      color: #fff;
      font-size: 1rem;
    }

    span {
      opacity: 0.9;
      font-size: 1.1rem;
      font-weight: bold;
    }
  }
}
.container-list-portals {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-portals {
  width: 50%;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.button-disable {
  background: none;
  border: none;
  color: #dc3545;
}

.button-enable {
  background: none;
  border: none;
  color: #524ec6;
}

.container-data-portals {
  text-align: justify;

  p {
    font-size: 1.2rem;
    font-family: Roboto;
    margin-bottom: 0.4rem;
  }
}
@import "../../../../index.scss";

.opportunities-function .btn-success,
.opportunities-function .btn-success.dropdown-toggle {
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.opportunities-function .btn-success:focus,
.opportunities-function .btn-success:active,
.opportunities-function .btn-success.dropdown-toggle:active {
  background-color: $primaryColor;
  border-color: $primaryColorDark;
  box-shadow: none;
}

.opportunities-function .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

container-export {
  width: 0.5rem;
  height: 2rem !important;
}

@import '../../../index.scss';

.login-input-control {
  width: 60% !important;
  margin: 0 auto;
}

.login-nav-text {
  font-size: 12pt;
  color: $textColor;
}

.button-login {
  width: 12rem;
  height: 2.5rem;
  margin: 1rem;
  margin-top: 1rem;
  border-radius: 0.3rem;
  border-color: $primaryColor;
  background-color: $primaryColor;
  padding: 0.5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  opacity: 0.9;

  &:hover {
    background-color: $submitButtonHoverColor;
    color: #fff;
    text-decoration: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  &:active {
    background-color: $submitButtonActiveColor  !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
    border-color: $submitButtonActiveColor  !important;
  }
}

.container-input-login {
  // position: absolute;
  justify-content: center;
  // align-items: center;
  height: 100%;
  flex-direction: column;
  display: flex;
}

.alert-gps {
  width: 80%;
  max-height: 4.5rem;
  // margin-top: 5%;
  // margin-bottom: 5%;
  margin-left: 10%;
  margin-right: 10%;
}

.legend-captcha {
  max-height: 4.5rem;
  font-size: medium;
}

.content-captcha {
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding-left: 4px;
}

@media(max-width: 1020px) {
  .alert-gps {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    font-size: 0.8rem;
  }

  .legend-captcha {
    max-height: 4.5rem;
    font-size: small;
    margin-top: -10px;
  }

  .content-captcha {
    margin-top: -18px;

    div {
      iframe {
        width: 200px !important;
        height: 68px !important;
      }
    }
  }
}
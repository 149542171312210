.loading-resources-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2000;
}

.loading-resources-main {
  position: fixed;
  width: 30%;
  height: 40vh;
  top: 50%;
  left: 50%;
  z-index: 1050;
  background-color: white;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.loading-resources-main p {
  color: rgb(47, 39, 155);
  font-size: 14pt;
  font-weight: bold;
}

.container-button-close {
  padding: 0.5rem;
  padding-bottom: 0rem;
  display: flex;
  justify-content: end;
}

.close-button {
  margin-top: 0px;
  background: none;
  padding: 0;
  outline: inherit;
  border: none;
}
@import '../../index.scss';

.top-menu-container {
  background-color: rgb(255, 255, 255);
  height: 4rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  margin-left: 60px;
}

.open-top-menu {
  margin-left: 240px;
}


.libo-topmenu-icon {
  display: inline;
  width: 220px;
  height: 66px;
  background-color: #1367ec;
}

.user-info-container img {
  width: 32px;
  height: 32px;
  filter: brightness(.4);
}

.libo-topmenu-icon:hover,
.user-info-container img:hover {
  cursor: pointer;
}

.hide-sidemenu {
  width: 60px;
}

.normalize-main-container {
  padding-left: 60px !important;
  padding-right: 15px !important;
}

.user-info-menu {
  border: 1px solid $textColorRest;
  border-radius: 40px;
  padding: 5px;
  box-shadow: 0 0 2px black;
}

.user-info-menu p {
  color: $topMenuTextColor;
  font-size: 12pt;
  text-align: center;
  font-weight: bold;
  display: none;
}

.userLogged {
  color: $topMenuTextColor;
  text-decoration: none;
}

.user-info-active {
  animation-duration: 2s;
  animation-name: slidein;
  animation-iteration-count: 1;
}

.button-feedback {
  background: none;
  border: none;
  padding: 0;
  outline: inherit;
}

@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.sourcesmenu-container1 {
  position: absolute;
  padding: 10px;
  height: 32px !important;
  top: 15px !important;
  right:280px;
  border-radius: 40px;
  display: inline-block !important;
  width: 32px !important;
  background-color: transparent;
  box-shadow: 0px 0px 10px rgba(142, 169, 190, 0.521);
  z-index: 10000px !important;

}

.sourcesmenu-container1 img {
  position:absolute;
  height: 53px !important;
  right: -12px !important;
  width: 50px !important;
  top: -11px !important;

}

.sourcesmenu-container2 {
  position: absolute;
  padding: 10px;
  height: 32px !important;
  top: 15px !important;
  right:240px  !important;
  border-radius: 40px;
  display: inline-block !important;
  width: 32px !important;
  background-color: transparent;
  box-shadow: 0px 0px 10px rgba(142, 169, 190, 0.521);
  z-index: 10000px !important;
}
.sourcesmenu-container2 img {
  position:absolute;
    height: 53px !important;
  right: -11px !important;
    width: 50px !important;
    top: -11px !important;
  }



@import "../../../index.scss";

.notification-info-context {
  background-color: $userNotBack;
  position: absolute;
  top: 3rem;
  right: -350px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  width: 320px;
  padding: 0;
  margin: 0;
  opacity: 0;
}

.notification-info-context.show {
  animation: 0.8s slideup forwards;
}

.notification-info-context.hidden {
  animation: 0.8s slidedown forwards;
}

@keyframes slideup {
  0% {
    opacity: 0;
    right: -350px;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes slidedown {
  0% {
    opacity: 1;
    right: 0;
  }

  100% {
    opacity: 0;
    right: -350px;
  }
}
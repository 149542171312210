.myAccountWrapper ul {
  text-decoration: none;
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

.myAccountWrapper li {
  text-decoration: none;
  text-align: left;
  margin: 0;
  padding: 0;
  // padding-left: 15px;
}

.container-main {
  margin-top: 30px;
  margin-bottom: 30px;
}

.container-user-information {
  justify-content: space-between;
  // align-items: center;
  display: flex;
  background-color: #fff;
  padding: 2rem !important;
  box-shadow: 0 0 0.6rem gray;
  height: 100%;
}

.container-data {
  margin-left: 3%;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.line-left-info {
  margin-left: 15px !important;
  text-align: left;
}

.line-right-info {
  margin-left: 15px !important;
  text-align: left;
}

.container-goal-information {
  justify-content: left;
  align-items: left;
  background-color: #fff;
  box-shadow: 0 0 1em gray;
  height: 100%;
  overflow: hidden;
}

.data-account {
  justify-content: left;
  align-items: left;
}

.container-search {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 15px;
}

.container-search-title {
  font-size: 110%;
  margin-bottom: 10px;
}

.container-date {
  margin-bottom: 30px;
  width: 100% !important;
}

.container-select-type {
  height: 50px;
}

#option-select {
  font-size: 100%;
}

.type-date {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 80px !important;
}

.type-month {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100px !important;

  button {
    width: 100% !important;
  }
}

.type-quarter {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 110px !important;
}

.type-semester {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 120px !important;
}

.type-year {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 120px !important;
}

.message-user {
  background-color: #fff !important;
  margin-bottom: 20px !important;
  overflow: hidden;
}

@media only screen and (max-device-width: 900px) {
  .container-main {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 0px !important;
    flex-direction: column;
  }

  .container-user-information {
    margin-right: 0px !important;
  }

  .container-goal-information {
    justify-content: left;
    align-items: left;
    background-color: #fff;
    box-shadow: 0 0 1em gray;
    height: 100%;
    margin-left: 15px !important;
    margin-right: 0px !important;
    margin-top: 30px;
  }

  .container-data {
    margin-right: 0px !important;
  }
}

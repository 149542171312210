@import "../../../index.scss";

.opportunity-piechart-wrapper h1 {
  font-size: 18pt;
  color: $textColor;
}

.dashboard-wrapper {
  position: relative;
  padding: 0 0 0 0px;
}

.oportunidadeAtiva {
  background: #152c4b;
  color: #fff;
}

.card-body {
  cursor: pointer;
}

.background-none ol {
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 3px;
  margin: 0;
}

.background-none li {
  color: #0056b3 !important;
  font-weight: bold;
  opacity: 0.8;
}

.left-icon-holder {
  line-height: 24px;
  margin-right: 10%;
}

.left-icon-holder .fa {
  position: absolute;
  line-height: 24px;
  top: 50%;
  margin-top: -12px;
  left: 10px;
}

.display-none {
  display: none;
}

.container-header {
  display: flexbox;
  flex-wrap: nowrap;
  margin-right: 10px !important;
  width: 100%;

  button {
    width: 100%;
  }
}

.form-style {
  height: 60px !important;
  max-width: 100%;
  min-width: 100%;
}

.select-search {
  height: 60px;
}

.cursor-pointer {
  cursor: pointer;
  transition: transform 0.3s;
}

.cursor-pointer:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.overflow-x-auto {
  overflow-x: scroll;
  overflow-y: hidden;
}

.button-search {
  height: 60px;
}

.button-filter {
  align-items: left;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: Ellipsis;
  justify-content: center;

  .origin-btn {
    height: 110px !important;
  }

  button {
    font-size: 100%;

    width: 16vw !important;
    // padding: 15px;
    margin-left: 10px;

    svg {
      margin: 10px;
    }

    p {
      font-size: 100%;
    }
  }
}

.grouped-cards>* {
  display: flex !important;
  white-space: normal;
  width: 100%;
  margin-left: 0px;
  flex-flow: wrap;
  justify-content: center;
}

@media only screen and (max-device-width: 1920px) and (min-device-width: 1590px) {
  .select-search {
    height: 60px;

    button {
      font-size: 16px !important;
    }
  }

  #option-select {
    font-size: 16px;
  }

  .form-style {
    height: 60px !important;
    max-width: 170px;
    min-width: 170px !important;
    font-size: 16px !important;
  }

  .button-search {
    height: 60px;
    border-radius: 0px 5px 5px 0px !important;
  }

  .button-filter {
    align-items: left;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: Ellipsis;
    justify-content: center;

    .origin-btn {
      height: 100px !important;
    }

    button {
      font-size: 100%;
      // height: 100px;
      width: 80%;
      // padding: 15px;
      margin-left: 10px;

      svg {
        margin: 10px;
      }

      p {
        font-size: 100%;
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }
  }
}

.legend-yellow {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  background-color: #1367ec;
  border-radius: 5px;
}

.legend-red {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  background-color: rgba(223, 71, 89, 1);
  border-radius: 5px;
}

.legend-blue {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  background-color: #1f1f1f;
  border-radius: 5px;
}

@media only screen and (max-device-width: 1590px) and (min-device-width: 1281px) {
  .select-search {
    height: 60px;

    button {
      font-size: 14px !important;
    }
  }

  #option-select {
    font-size: 14px;
  }

  #input-search {
    height: 16px;
  }

  .form-style {
    height: 60px !important;
    max-width: 155px;
    min-width: 155px !important;
    font-size: 14px !important;
  }

  .button-search {
    height: 60px;
  }

  .button-filter {
    align-items: left;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: Ellipsis;
    justify-content: center;

    .origin-btn {
      height: 110px !important;
    }

    button {
      font-size: 90%;
      // height: 90%;
      width: 12vw;
      // padding: 15px;
      margin-left: 8px;

      svg {
        margin: 10px;
      }

      p {
        font-size: 100%;
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: Ellipsis;
        margin-bottom: 10px;
        margin-top: 0px;
        margin-left: 0px;
      }
    }
  }
}

@media only screen and (max-device-width: 1280px) and (min-device-width: 1040px) {
  .select-search {
    height: 60px;

    button {
      font-size: 14px !important;
    }
  }

  #option-select {
    font-size: 14px;
  }

  #input-search {
    height: 16px;
  }

  .form-style {
    height: 60px !important;
    max-width: 155px;
    min-width: 155px !important;
    font-size: 14px !important;
  }

  .button-search {
    height: 60px;
  }

  .button-filter {
    align-items: left;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: Ellipsis;
    justify-content: center;

    .origin-btn {
      height: 100px !important;
    }

    button {
      font-size: 100%;
      // height: 85%;
      width: 20%;
      // padding: 15px;
      margin-left: 10px;

      svg {
        margin: 10px;
      }

      p {
        font-size: 80%;
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: Ellipsis;
        margin-bottom: 10px;
        margin-top: 0px;
        margin-left: 0px;
      }
    }
  }
}

@media only screen and (max-device-width: 1040px) and (min-device-width: 880px) {
  .select-search {
    height: 50px;

    button {
      font-size: 12px !important;
    }
  }

  #input-search {
    height: 14px;
  }

  .form-style {
    height: 50px !important;
    max-width: 135px;
    min-width: 135px !important;
    font-size: 12px !important;
  }

  .button-search {
    height: 50px;
  }


  .button-filter {
    align-items: left;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: Ellipsis;
    justify-content: center;

    .origin-btn {
      height: 80% !important;
    }

    button {
      // height: 80%;
      width: 20%;
      // padding: 15px;
      margin-left: 10px;

      svg {
        margin: 10px;
      }

      p {
        font-size: 60%;
        overflow: hidden;
        margin-bottom: 10px;
        margin-top: 0px;
        margin-left: 0px;
      }
    }
  }

}

.limitador {
  height: 768px;
}
@import "../../../../index.scss";

.menubutton-wrapper {
    width: 120px;
    height: 120px;
    background-color: $opportunityButton;
    border-radius: 20px;
    box-shadow: inset 0 0 10px $opportunityButtonShadow;
}

.menubutton-wrapper:hover {
    background-color: $opportunityButtonHover;
    cursor: pointer;
}

.menubutton-wrapper:active {
    background-color: $opportunityButtonActive;
}

.menubutton-wrapper img{
    width: 40px;
    height: 40px;
}

.menubutton-wrapper p{
    color: white;
    font-weight: bold;
    font-size: 12pt;
    margin-top: 10px;
}
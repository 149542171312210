@import "../../../../index.scss";

.headerModal {
  height: 30%;
  padding: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 25px;
  margin-right: 15px;
  color: $primaryColorDark;
}

.sub-title {
  font-weight: 200;
  font-size: 70%;
  color: primaryColor;
}

.container-justificativa {
  flex-direction: row;
  display: flex;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 10px;
  justify-content: center;
}

.input-justificativa {
  width: 45%;
  height: 15%;
  margin-left: 5px;
  font-style: italic;
}

.container-radio {
  flex-direction: row;
  display: flex;
  justify-content: left;
  margin-left: 5%;
  vertical-align: middle !important;
  align-items: baseline;
}

.input-radio {
  margin-right: 15px;
}

.container-btn-decline {
  justify-content: center;
  display: flex;
}

.btn-decline {
  color: #fff;
  background-color: #3169b3;
  border-color: #3169b3;
  width: 15%;
  height: 40px;
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
  border-radius: 3px;
}

.btn-decline:hover {
  color: #fff;
  background-color: rgba(49, 105, 179, 0.8);
  border-color: rgba(49, 105, 179, 0.8);
}

.alert-control {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.title-alert {
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 1rem;
}
@import "../../../index.scss";

.datatable-title {
  font-size: 16pt;
  width: 100%;
  text-align: left;
  color: $textColor;
  font-weight: 400;
}

.datable-main {
  table-layout: fixed;
}

.datatable-header-col>div>p {
  margin: auto;
  text-align: center;
  align-content: center;
  width: 100%;
  font-size: 12pt;
}

.datable-head-row {
  background-color: transparent;
}

.proposalToggleWIdth {
  width: 200px !important;
}

.dl-button:hover {
  cursor: pointer;
  color: #333;
}

.datatable-header-col {
  color: $primaryColor  !important;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
  font-size: 11pt;
  border-bottom: 1px solid $textColorRest;
}

.datatable-row {
  height: 50px;
  padding: 0 20px !important;
}

.datatable-row-col {
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $textColor;
  text-align: center;
  align-content: center;
  font-size: 10pt;
  font-weight: 400;
  border-bottom: 1px solid rgba(209, 209, 209, 0.582);
}

.datatable-row-odd {
  background-color: #f8f9fa;
  margin: 0;
}

.datatable-row-odd th,
.datatable-row-even th {
  margin: 0;
}

.datatable-row-odd:hover th,
.datatable-row-even:hover th {
  color: white;
  font-weight: bold;
}

.datatable-row-even:hover,
.datatable-row-odd:hover {
  background-color: $tableHover;
  cursor: pointer;
  margin: 0;
}

.datatable-row-even {
  background-color: white;
}

.datatable-outer {
  overflow-x: auto;
}

.datatable-outer th,
.datatable-outer td {
  min-width: 100px;
}

.arrow-up {
  background-image: url("../../../assets/right-arrow.svg");
  width: 20px;
  height: 20px;
  transform: rotate(-90deg);
  filter: brightness(0.6);
  opacity: 0;
}

.arrow-down {
  background-image: url("../../../assets/right-arrow.svg");
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
  filter: brightness(0.6);
  opacity: 0;
}

.arrow-up.show,
.arrow-down.show {
  opacity: 1;
}

.datatable-header-col:hover,
.datatable-header-col:hover .arrow-up,
.datatable-header-col:hover .arrow-down {
  cursor: pointer;
  opacity: 1;
}

.unviewed {
  font-weight: bold;
}

.container-select {
  justify-content: end;
  width: 100%;
}

.select-manufacturers {
  width: 18vw;
  font-size: 0.9vw;

  :hover {
    cursor: pointer;
  }
}

.select-month {
  width: 11vw;
  font-size: 0.9vw;

  :hover {
    cursor: pointer;
  }
}

.table-ranking {
  height: 56vh !important;
  width: 90%;
  display: block;
  overflow-y: auto;
  border: thin;

  thead {
    background: rgba(169, 169, 169, 0.8);
    text-align: center;
    vertical-align: middle;
  }

  tbody {
    text-align: center;
    vertical-align: middle;
  }
}

.bar-chart-ranking {
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    .apexcharts-svg {
      width: 1000px;
    }
  }
}

.container-spinner {
  width: 850% !important;
  align-items: flex-end;
}

.container-legenda-ranking {
  margin-top: 5%;
  margin-bottom: 2.5%;
  margin-left: 10%;
  margin-right: 10%;
  width: 90%;
  background: white;
  text-align: justify;
}

.legenda-ranking-quoted {
  color: rgba(49, 105, 179, 1);
  font-size: 100% !important;
}

.legenda-ranking-presented {
  font-size: 130% !important;
  color: #00a000;
}

@media only screen and (max-device-width: 1460px) {
  .table-ranking {
    width: 80%;

    thead {
      font-size: 12px;

      .select-item-ranking {
        width: 80%;
      }
    }

    tbody {
      font-size: 12px;
    }
  }
}

@media only screen and (max-device-width: 1360px) {
  .bar-chart-ranking {
    width: 100%;
    display: flex;
    justify-content: center;

    div {
      .apexcharts-svg {
        width: 750px;
      }
    }
  }
}
@import "../../../index.scss";

.link {
  color: #524ec6;
}

.presentation {
  max-width: 300px;
  max-height: 150px;
  width: auto;
  height: auto;
}
.container-success-feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  padding-top: 2rem;
}

.message-success {
  margin-top: 1rem;
  font-size: large;
}

.button-new-feedback {
  border: none;
  background-color: #524ec6;
  color: white;
  border-radius: 5px;
  height: 35px;
  width: 170px;
  margin-top: 1rem;
}
@import "../../../../index.scss";

.attachment-function .btn-success,
.attachment-function .btn-success.dropdown-toggle {
	background-color: transparent;
	border-color: transparent;
}

.attachment-function .btn-success:focus,
.attachment-function .btn-success:active,
.attachment-function .btn-success.dropdown-toggle:active {
	background-color: transparent;
	border-color: transparent;
	box-shadow: none;
}

.attachment-function .btn-success.dropdown-toggle:focus {
	box-shadow: none;
	border-color: transparent;
	border: 1px solid #ddd;
}

.attachment-img {
	width: 2rem;
	height: 2rem !important;
	 margin-left: -10px !important;
}

.paperClipColor {
	color: #3169b3;
	margin-right: 0rem;
	margin-left: 0.1rem;
	height: 2rem !important;
	width: 2rem;
}

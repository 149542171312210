.overflow-y-modal {
  overflow-y: scroll;
  height: 65vh;
}

.display-none {
  display: none;
}

.separator {
  border: 2px solid #ddd;
  height: 2px;
  width: 100%;
}
@import "../../../index.scss";

.opportunity-view-wrapper {
  padding: 20px 0 0 20px;
}

.opportunity-view-item {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.opportunity-view-opt {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.btn-rounded {
  border-radius: 50%;
}

.opportunity-view-header-box {
  margin-top: 10%;
  height: 100% !important;
  width: 100% !important;
  background-color: $primaryColor;
  justify-content: start;
  padding-top: 10px;
}

.opportunity-view-header-box p {
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.opportunity-view-field-wrapper {
  margin-top: 30px !important;
  width: 400px;
  margin-bottom: 0px !important;
}

.opportunity-view-label {
  color: $primaryColorDark;
  font-size: 15pt;
  text-align: left;
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.opportunity-view-text {
  color: $primaryColor;
  font-size: 14pt;
  text-align: left;
  word-break: break-all !important;
  margin: 0;
  padding: 0;
}

.opportunity-view-field-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn {
  padding: 0.175rem 0.35rem;
}

.container-initial {
  width: 100%;
  flex-wrap: nowrap;
  height: 6rem;
}

.container-clip {
  justify-content: end;
  margin-top: 10%;
  margin-right: 10%;
}

.container-decline-opportunity {
  justify-content: end;
  margin-top: 1rem;
}

@media only screen and (max-device-width: 1430px) {
  .opportunity-view-header-box {
    margin-top: 10%;
    p {
      font-size: 14px !important;
    }
  }
}

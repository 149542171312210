.container-search {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: -10px;
  margin-right: 5px;
}

.container-date {
  margin-bottom: 30px;
  width: 100% !important;
}

.container-select-type {
  height: 50px;
}

.display-none {
  display: none;
}

.type-month {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100px !important;

  button {
    width: 100% !important;
  }
}

.type-quarter {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 120px !important;
}

.type-semester {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 120px !important;
}

.type-year {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 120px !important;
}

.type-date {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 80px !important;
}

.type-opportunity {
  height: 50px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  max-width: 15% !important;
}

.container-table {
  padding: 0;
  margin-left: 6px;
  margin-right: -6px;
  margin-top: -20px;
}

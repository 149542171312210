@import '../../../index.scss';

.not-found-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
}

.not-found-wrapper h1 {
  color: $notFoundDarkTextColor;
  font-size: 46pt;
}

.not-found-wrapper p {
  color: $notFoundTextColor;
  font-size: 20pt;
  width: 40%;
}

.not-found-button {
  margin-top: 50px;
  border: 2px solid $submitButtonColor;
  color: $submitButtonColor;
  font-size: 16pt;
  font-weight: bold;
  padding: 10px;
  width: 10%;
  text-decoration: none !important;
}

.not-found-button:hover {
    background-color: $lightColor;
    color: $textColor;
    border: 2px solid $textColor;
}
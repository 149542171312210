// theme
$primaryColor: #1367ec;
$primaryColorDark: #1f1f1f;
$secondColor: #1f1f1f;
$secondColorDark: #000000;
$lightColor: #f5f5f5;
$mainBackgroundColor: rgb(245, 245, 245);
$floatingFilter: rgba(255, 255, 255, .6);
$floatingBar: rgba(255, 255, 255, .8);
$disabledItem: #555555;

$textColor: rgb(56, 56, 56);
$topMenuTextColor: rgb(134, 134, 134);
$textColorRest: #eaeaea;
$notFoundTextColor: rgb(47, 143, 223);
$notFoundDarkTextColor: rgb(23, 99, 161);
$errorTextColor: #fff;
$errorTextColor: #fff;

//Cards
$cardMainColor: #fff;
$indexCardColor: rgb(70, 125, 196);
$manufacturerBackgroundColor: #fff;

// Buttons
// Submit Button
$submitButtonColor: #5c58c9;
$submitButtonHoverColor: #5551c7;
$submitButtonActiveColor: #5551c7;
// Opportunity button
$opportunityButton: rgb(99, 143, 194);
$opportunityButtonHover: rgb(127, 168, 214);
$opportunityButtonActive: rgb(30, 96, 172);
$opportunityButtonShadow: rgb(181, 208, 240);

// Notifications
$notificationSuccess: #524ec6;
$notificationAlert: rgb(0, 0, 0);
$notificationCommom: #fff;
$notificationError: rgb(236, 85, 85);
$notificationText: #fff;

// UserNotification
$userNotBack: #fff;
$userNotBorders: rgb(245, 245, 245);
$userNotButton: #fff;
$userNotActive: #fff;

// Table
$tableHover: #6fb5fa;

// Calendar 
$dateStart: rgba(53, 90, 170, 0.5);
$dateEnd: rgba(255, 0, 43, 0.5);
$dateBorderThick: 4px;

//button

.btn-flat {
  font-size: large;
  color: #000000;
  background-color: #dbdbdb;
  border-color: #000000;
}

.btn-flat span{
  color: #000000;

}

.btn-flat span:hover{
  color: #ffffff;

}

.btn-flat:hover {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #000000;
}

.btn-flat-not {
  color: #000000;
  background-color: #dbdbdb;
  border-color: #000000;
}

.btn-flat-not:hover {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #000000;
}


.btn-flat-search {
  font-size: large;
  color: #000000;
  background-color: #dbdbdb;
  border-color: #092a60;
}

.btn-flat-search span{
  color: #fff;

}

.btn-flat-search:hover {
  color: #ffffff;
  background-color: #7c7c7c;
  border-color: #000000;
}

//original
/*
// theme
$primaryColor: #524ec6;
$primaryColorDark: #3a36a7;
$secondColor: #5551c7;
$secondColorDark: #3c38ae;
$lightColor: rgb(214, 237, 255);
$mainBackgroundColor: rgb(245, 245, 245);
$floatingFilter: rgba(255, 255, 255, .6);
$floatingBar: rgba(255, 255, 255, .8);
$disabledItem: rgb(141, 141, 141);
//Texts
$textColor: rgb(56, 56, 56);
$topMenuTextColor: rgb(134, 134, 134);
$textColorRest: #eaeaea;
$notFoundTextColor: rgb(47, 143, 223);
$notFoundDarkTextColor: rgb(23, 99, 161);
$errorTextColor: #fff;
$errorTextColor: #fff;

//Cards
$cardMainColor: #fff;
$indexCardColor: rgb(70, 125, 196);
$manufacturerBackgroundColor: #fff;

// Buttons
// Submit Button
$submitButtonColor: #5c58c9;
$submitButtonHoverColor: #5551c7;
$submitButtonActiveColor: #5551c7;
// Opportunity button
$opportunityButton: rgb(99, 143, 194);
$opportunityButtonHover: rgb(127, 168, 214);
$opportunityButtonActive: rgb(30, 96, 172);
$opportunityButtonShadow: rgb(181, 208, 240);

// Notifications
$notificationSuccess: #524ec6;
$notificationAlert: rgb(253, 142, 52);
$notificationCommom: rgb(98, 207, 76);
$notificationError: rgb(236, 85, 85);
$notificationText: #fff;

// UserNotification
$userNotBack: #fff;
$userNotBorders: rgb(245, 245, 245);
$userNotButton: #fff;
$userNotActive: #fff;

// Table
$tableHover: #6fb5fa;

// Calendar 
$dateStart: rgba(53, 90, 170, 0.5);
$dateEnd: rgba(255, 0, 43, 0.5);
$dateBorderThick: 4px;

//button

.btn-flat {
  color: #fff;
  background-color: #524ec6;
  border-color: #524ec6;
}

.btn-flat:hover {
  color: #fff;
  background-color: #3a36a7;
  border-color: #3a36a7;
}
*/
//Texts

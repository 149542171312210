.notification-bar-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  background-color: transparent;
  z-index: 1000;
}

.notification-list-wrapper {
  list-style-type: none;
}

.teste-btn{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 2000;
}

  
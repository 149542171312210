@import '../../../../index.scss';

.notification-item-wrapper {
  height: 80px;
  position: relative;
}

.notification-item-wrapper.success {
  background-color: $notificationSuccess;
}

.notification-item-wrapper.error {
  background-color: $notificationError;
}

.notification-item-wrapper.alert {
  background-color: $notificationAlert;
}
.notification-item-wrapper.common {
  background-color: $notificationCommom;
}

.close-notification-btn {
  position: absolute;
  border: none;
  background-color: transparent;
  top: 0;
  right: 0;
  font-size: 8pt;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  margin: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.notification-item-header {
  position: absolute;
  color: $notificationText;
  font-size: 12pt;
  font-weight: bold;
  top: 5px;
  text-align: top;
}

.notification-item-text {
  font-size: 12pt;
  color: $notificationText;
}

.side-notifcation-show {
    animation: ease-in .5s slide-in forwards;
}

.side-notifcation-hide {
    animation: ease-out .5s slide-out forwards;
}

@keyframes slide-in {
    from {
        right: -100%;
    }

    to {
        right: 0%;
    }
}

@keyframes slide-out {
    from {
        right: 0%;
    }

    to {
        right: -100%;
    }
}
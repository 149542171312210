@import '../../../../../index.scss';

.sourceitem-wrapper {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: rgb(255, 255, 255);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 2px solid $primaryColorDark;

}

.sourceitem-selected {
  background-color: $primaryColor;
  border: 4px solid $primaryColorDark;
  color: white;
}

.sourceitem-wrapper p {
  color: $textColor;
  font-size: 30pt;
  font-weight: bold;
}

.sourceitem-wrapper:hover {
  cursor: pointer;
  background-color: $lightColor;

}



.sourceitem-selected p {
  color: white !important;
}

/*                          */

.item-selected {
background-color: transparent;
}

.sourceitem-container:hover .sourceitem-back {
  cursor: pointer;
background-color: transparent;
}

// .item-selected {
//   border: 10px solid #f3f3f3;
//   border-radius: 50%;
//   border-top: 10px solid $primaryColorDark;
//   border-left: 10px solid $primaryColorDark;
//   border-bottom: 10px solid $primaryColorDark;
// }

// .sourceitem-container:hover .sourceitem-back {
//   cursor: pointer;
//   border: 10px solid #f3f3f3;
//   border-radius: 50%;
//   border-top: 10px solid $notificationCommom;
//   border-left: 10px solid $notificationCommom;
//   border-bottom: 10px solid $notificationCommom;
// }

.sourceitem-back,
.sourceitem-back:hover {
  position: relative;
  top: 0;
  left: 0;
  width: 50px;
  height: 40px;

  
}

/* Safari */

.dropdown-pagination .btn-primary {
  background-color: transparent;
  color: rgb(119, 119, 119);
  font-size: 10pt;
  padding: 0;
  margin: 0;
  border-color: transparent;
  box-shadow: none;
}

.dropdown-pagination .btn-primary:hover {
  background-color: transparent;
  color: rgb(119, 119, 119);
}

.dropdown-pagination .btn-primary:focus {
  background-color: transparent;
  color: rgb(119, 119, 119);
  border-color: transparent;
}

.datatable-pagination-text{
  font-size: 10pt;
  color: rgb(119, 119, 119);
  margin: 0;
  margin-right: 10px;
}

.pagination-btn-control{
  border: none;
  background-color: transparent;
  margin: 0 10px;
  color: rgb(119, 119, 119);
}

.right-arrow-line {
  background-image: url("../../../../assets//right-arrow-line.svg");
  filter: brightness(0.5);
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}

.right-arrow {
  background-image: url("../../../../assets//right-arrow.svg");
  filter: brightness(0.5);
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}

.left-arrow-line {
  background-image: url("../../../../assets//left-arrow-line.svg");
  filter: brightness(0.5);
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}

.left-arrow {
  background-image: url("../../../../assets//right-arrow.svg");
  transform: rotate(180deg);
  filter: brightness(0.5);
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 30px;
}
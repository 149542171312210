@import "../../../../index.scss";

.container {
  max-width: 100%;
  margin-top: 30px;
}

.container,
.card-body {
  cursor: inherit;
}

.btn-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.btn-circle svg {
  margin-left: 2px;
  margin-right: 2px;
}

.limit-height {
  height: 10em;
}

.overflow-y {
  height: 400px;
}

.btn-hover:hover {
  border: 1px solid #3a36a7;
}

.display-none {
  display: none;
}

.text-flat {
  color: #524ec6;
  background-color: #fff;
  border-color: #fff;
}

.text-flat:hover {
  color: #3a36a7;
  background-color: #fff;
  border-color: #fff;
}

.text-danger:hover {
  border-color: #fff;
}
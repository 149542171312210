@import "../../../index.scss";

#logoHeader {
  padding: 0px 15px;
  width: 250px;
}

#header {
  background-color: #3169b3;
  height: 10rem !important;
}

#buttonHeader {
  margin-top: 60px;
}

#titleHeader {
  margin-top: 60px;
  color: #fff;
  font-size: 1.5rem;
}

.formField__error {
  color: #d93025;
  font-size: 16px;
}

.teste {
  margin-bottom: 20px;
}

.input-token {
  width: 40rem;
  height: 2.3rem;
  border-radius: 5px;
  border-width: 1px;
  border-color: gray;
  text-align: center;
}

.alert-guest {
  width: 60% !important;
}
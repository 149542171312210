@import "../../../index.scss";

.container {
  max-width: 100%;
  margin-top: 30px;
}

.container,
.card-body {
  cursor: inherit;
}

.cat-limiter {
  overflow-y: scroll;
  height: 25em;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.container-headers-list {
  text-align: initial;
}

.container-data-user {
  align-items: center;
  padding: 0.8rem;
  padding-left: 1rem;
  display: flex;
  justify-content: left;
}

.container-buttons-actions {
  display: flex;
  align-items: center;
  justify-content: left;
}
@import './index.scss';

.App {
  text-align: center;
  font-family: "Roboto", sans-serif;
  height: 768px;
}

.main-content-container {
  padding: 0 !important;
  margin: 0 !important;
  padding: calc(4rem) 0 0 240px !important;
  transition: all .3s ease-in-out;
  background-color: $mainBackgroundColor;
}
.breadcrumb-wrapper,
.breadcrum-divider {
  color: rgb(99, 99, 99);
  opacity: .9;
}

.active-breadcumb-item {
  color: #3a36a7;
  font-weight: bolder;
}

.breadcrumb-wrapper:hover {
  text-decoration: none;
}